import * as React from "react";
import { Link } from "gatsby";
import Layout from "../../components/Layout";
import Head from "../../components/Head";
import DownloadBox from "../../components/DownloadBox";
import DataBlueprintConcept from "../../images/data-blueprint-concept.png";

const ContactPage = () => (
  <Layout>
    <Head title="[Data] Blueprint" />

    {/* Sub Header */}
    <div className="bg-blue-800">
      <div className="container py-16 sm:py-56 text-white">
        <h1 className="text-4xl sm:text-7xl leading-tight sm:leading-tight font-bold text-center">
          <span className="whitespace-nowrap">
            <span className="text-yellow-800 text-5xl sm:text-8xl pr-1 sm:pr-2">[</span>Data<span className="text-yellow-800 text-5xl sm:text-8xl pl-1 sm:pl-2">]</span>
          </span>{" "}
          Blueprint
        </h1>
        <p className="max-w-screen-lg mx-auto text-center text-xl leading-snug sm:text-3xl sm:leading-normal pt-8 sm:pt-16">Technical Roadmap to achieving low risk, reliable, and sustainable e-Government system</p>
      </div>
    </div>

    {/* Content */}
    <div className="container max-w-screen-xl sm:text-2xl pt-12 sm:pt-32">
      <h2 className="_h2">What is [Data] Blueprint?</h2>
      <p className="_p">
        [Data] Blueprint is the product name of the comprehensive data system designing, building and validation process that Levercode offers to Governments and businesses that process (collect, own, analyze, share, and govern) high volume of data. As a
        whole [Data] Blueprint Concept consists of three phases - Discovery, Technical Roadmap, and Validation.
      </p>

      <p className="my-12 sm:my-24">
        <img src={DataBlueprintConcept} alt="Data Blueprint Concept" />
      </p>

      <p className="_p">
        In building data systems or developing e-services, there is a high risk of ending up with the “machine” that either fails to deliver expected and/or promoted products or services entirely, lack in functionality, interoperability, efficiency, and
        productivity, or put processed sensitive data at risk of misuse or illicit disclosure. [Data] Blueprint concept methodology, created by Levercode, helps to address mentioned issues and risks, and eliminate or mitigate them{" "}
        <strong>by focusing primarily on data models, processes, stakeholders, and legislation</strong>.
      </p>

      <p className="_p">
        In principle, the Blueprint is created on request of the owner of the system or process and deals with the issue or problem specifically pointed at by them. Since usually there are no quick fixes to issues without knowing to the roots and details
        of the system, <strong>Levercode performs thorough analysis of the data governance and operational processes</strong> and identifies the weaknesses, bottlenecks, and errors in the data systems. The outcome is the{" "}
        <strong>detailed roadmap to achieving low risk, reliable, and sustainable system</strong>.
      </p>
    </div>

    <div className="container max-w-screen-xl pt-12 sm:pt-32 sm:text-2xl">
      <h2 className="_h2">Who needs [Data] Blueprint and why?</h2>
    </div>

    <div className="container max-w-screen-3xl pt-8 sm:pt-24 sm:text-2xl">
      <div className="grid grid-col-1 lg:grid-cols-2 gap-8 sm:gap-16">
        <div>
          <p>
            <strong>For Governments</strong>, Blueprint provides necessary guidance for conducting country wide Digital Transformation and transition to e-Governance. Blueprint goes further than traditional Digitalization Maturity Assessment as it
            addresses the issues, processes and technical solutions in more detail and comprehensive way. Clients will get clear specification of the “to be” ecosystem which allows them to step into procurement of specific components or technologies right
            away. As part of the package, Levercode will also propose changes to the national legislation that are necessary for securing effective operation of “to be” systems.
          </p>
        </div>
        <div>
          <p>
            <strong>For businesses</strong>, the Blueprint provides specification for addressing data governance issues and mission critical processes. Although the process of achieving the Blueprint for a business entity is largely similar to that
            provided to Governments, there are also remarkable differences which stem primarily from the specific business that companies are active in and their respective needs. Also, regulative environments (legal restrictions such as GDPR, AML, and
            KYC) have a lot to say what kind of processes and aspects need to be covered by the Blueprint.
          </p>
        </div>
      </div>
    </div>

    <div className="container max-w-screen-xl sm:text-2xl pt-12 pb-16 sm:pt-32 sm:pb-56">
      <h2 className="_h2">How does [Data] Blueprint look like?</h2>
      <p className="_p">
        Blueprint entails extensive analytics and modelling. The process usually starts with <strong>Discovery phase</strong> which is meant to fully describe the “as is” state of play. We usually start the Discovery from scratch. However, if there are
        already such analyses done by reliable international organizations, their reports and conclusions need to be validated before it is possible to build our own work on them and move forward with the process. The result of Discovery phase is a
        detailed overview of the system existing today, including the description of the weaknesses in data processing, operational models, and gaps in legislation.
      </p>
      <p className="_p">
        More importantly, we also create as accurate as possible <strong>description of what the system should look like in the future</strong>, so called “to be” version. That description is provided in the form of <strong>Technical Roadmap</strong>. Its
        value and purpose is to give to the client/owner the specification of building up its data systems in advanced, reliable, and sustainable way. Blueprint is also meant to serve as a prescription for action of other contributors. For instance,
        Governments can decide on Digital Identity and Interoperability models, IT departments procure specific technology components necessary to building up envisioned infrastructure, and programmers start with code writing for provision of new
        e-services.
      </p>
      <p className="_p">
        After implementation of the Roadmap, <strong>Validation phase</strong> is meant to evaluate if the efforts in taking the systems to the “to be” state has been successful and objectives (e.g. low risk, high reliability and sustainability) have been
        achieved. Validation phase also gives an opportunity to evaluate the technological landscape at the time and plan future changes, if deemed necessary.
      </p>

      <DownloadBox text="Download PDF whitepaper – [Data] Blueprint" url="https://levercode.com/downloads/data-blueprint-by-levercode.pdf" />

      <h2 className="_h2 mt-12 sm:mt-32">How to move forward?</h2>

      <p className="_p">
        Please{" "}
        <Link to="/contact" className="_link font-bold">
          contact us
        </Link>{" "}
        to learn more about [Data] Blueprint and Levercode’s approach to achieving Digital Transformation. We would be happy to schedule free consultation with you to learn more about your situation, needs, and goals with a view of helping your country or
        organization to solve the issues and achieve your objectives.
      </p>
    </div>
  </Layout>
);

export default ContactPage;
